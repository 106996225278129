import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import PlayVideo from '../../../components/Play/PlayVideo';
import Modal from "react-bootstrap/Modal"
import { Container, Row, Col } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll";
import HTMLReactParser from 'html-react-parser';
import { youtube_parser } from "../../common/utils";
import {ImageModule} from "../../../modules/Image_Module"
import MemberForm from "../../forms/member-form"

import "./PersonDetails.scss"
// Header component

const PersonDetails = (props) => {
    const [isPlay, setPlay] = useState(false);
    const [isOpen,setIsOpen] = useState(false);

    var youtube_id = props.people_details && props.people_details.Embed_Video_URL && youtube_parser(props.people_details.Embed_Video_URL);

    return (
        <React.Fragment>
            <section className="person-details">
                <Container>
                    <Row>
                        <Col>
                        {
                            props.people_details && 
                            <div className="details-wrapper">
                                <div className="surveyor-img img-zoom">
                                    <ScrollAnimation animateIn='fadeInRight' animateOnce={true}>
                                        <picture>
                                            {props.people_details.Image && 
                                                <ImageModule ImageSrc={props.people_details.Image } ggfx_results={props.people_details.ggfx_results} imagename="peoples.Image.detail_image" strapi_id={props.people_details.id} altText={props.people_details.Name+ " - Strettons"}/> 
                                            }
                                        </picture>
                                    </ScrollAnimation>
                                    {
                                        props.people_details.Embed_Video_URL && 
                                        <div className="video-buttons" onClick={(e) => { setPlay(true) }}>
                                            <strong className="video-btn">
                                                <i className="icon-video-black"></i>
                                            </strong>
                                        </div>
                                    }
                                </div>
                                <div className="details-info">
                                    <ScrollAnimation animateIn='fadeInLeft' animateOnce={true}>
                                        <h1>{props.people_details.Name}{props?.people_details?.Qualification ? <span className="qual_txt">{', '+props?.people_details?.Qualification}</span> : ''}</h1>
                                        <div className="about-wrapper">
                                            <div className="about-info departments">
                                                <a className="no_hover">
                                                    <i className="icon-identity"></i>
                                                    
                                                        {/* {props.people_details.people_department?.Name} */}
                                                        {props.people_details.people_departments?.length > 0 && <> {props.people_details.people_departments?.map(item => item.Name).toString(", ").replace(/,/g, ', ')}</> }
                                                    
                                                </a>
                                            </div>
                                            <div className="about-info" onClick={() => setIsOpen(true)}>
                                                <a href={`javascript:;`} className="mail" ><i className="icon-msg"></i>{props.people_details.Email_ID}</a>
                                                
                                            </div>
                                            <div className="about-info">
                                                <a className="no_hover"><i className="icon-auhtor"></i></a>
                                                {props.people_details.Level}
                                            </div>
                                        </div>
                                        <div className="btn-wrapper">
                                            <a href="javascript:;" onClick={(e)=>props.handleClick("contact_office")}  className="btn btn-primary">
                                                message
                                            </a>
                                            <a href={`tel:+${props.people_details.Phone_Number}`} className="btn btn-primary">
                                                call {props.people_details.Phone_Number}
                                            </a>

                                            <a href={props.people_details.Linked_In_Link} target={"_blank"} className="btn btn-primary">
                                                <i className="icon-linkedln"></i>
                                                LinkedIn
                                            </a>
                                        </div>

                                        {props.people_details.Content && HTMLReactParser(props.people_details.Content)}

                                    </ScrollAnimation>
                                </div>
                            </div>
                        }
                            
                        </Col>
                    </Row>
                </Container>
            </section>
            <PlayVideo
                isOpen={isPlay}
                isCloseFunction={setPlay}
                videoId={youtube_id}
                isAutoPlay={1}
            />

            <Modal
                id="popup_modal_form"
                show={isOpen}
                onHide={() => setIsOpen(false)}
                backdrop="static"
                keyboard={false}
                className="member-contact-modal"
            >
                {/* <Button variant="" onClick={this.closeModal} className="p-0 closepopup"><i className="icon icon-close-popup"></i></Button> */}
                <Modal.Header closeButton className="contact-close-btn memberPopup">
                <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">Contact - {props.people_details.Name}</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <MemberForm to_email_id={props.people_details.Email_ID} to_user_id={props.people_details.Form_Queue_ID} formName={`Individual Contact - ${props.people_details.Name}`} formLabel={`Person ${props.people_details.Name}`} />
                </Modal.Body>
            </Modal>

        </React.Fragment>
    );
};
export default PersonDetails;
