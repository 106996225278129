import React, {useState, useEffect, useRef, createRef} from "react"
import { Helmet } from "react-helmet";
import { Container, Row, Card, Col } from "react-bootstrap";
import axios from "axios"
import $ from "jquery"

import Layout from "../components/layout";
import SEO from "../components/seo";
import {People_Details} from "../queries/common_use_query"

import PersonDetails from "../components/PersonDetails/PersonDetails/PersonDetails"
import Expertise from "../components/PersonDetails/AreaExpertise/Expertise"
import PersonLinks from "../components/PersonDetails/PersonLinks"
import RelatedNews from "../components/Home/News/PeopleInsights";

import {capitalize} from '../components/common/utils';

import BrandLogo from "../images/logo002.svg";

import ContactFormBottom from "../components/FormBottom/ContactFormBottom";

function PeopleDetailsTemplate(props) {

  //console.log("search", search);
  const [search, setSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const [menu_heading, setMenuHeading] = useState(false);
  const [state, setState] = React.useState({
      showMenu: false,
  });

  const [people_details, SetDetails] = useState("");
  const {loading, error, data} = People_Details(props.pageContext?.slug);

  useEffect(()=>{
    data && data.peoples.length > 0 && SetDetails(data.peoples[0]);
  },[data])

  var myMetaTitle = "";
  var myMetaDesc = "";
  var myMetaImg = "";

  if( props?.pageContext?.title ) {
      myMetaTitle = capitalize(props.pageContext.title)+" | "+props.pageContext.Level;
      myMetaDesc = "Get to know about "+capitalize(props.pageContext.title)+" here. Contact one of our estate agents for assistance in finding the right property for you.";
  }

    let processedImages = JSON.stringify({});
    const STAGE_ENV = process.env.GATSBY_STRAPI_GGFX_ENV || "i.dev";
    
    if (people_details?.imagetransforms?.Image_Transforms) {
      processedImages = people_details?.imagetransforms?.Image_Transforms;
      var resized_images = JSON.parse(processedImages);
      const SrcCftle = people_details?.Image &&  people_details?.Image?.url.substring(people_details?.Image && people_details?.Image?.url.indexOf(STAGE_ENV));
      const ProcessedUrl = resized_images[SrcCftle] && resized_images[SrcCftle]?.jpg ? resized_images[SrcCftle]?.jpg['480ct320'] : people_details?.Image && people_details?.Image?.url;
      myMetaImg = ProcessedUrl;
    }

  const scrollToPos = (div_id, timeout) =>{
    setTimeout(function(){
      $('html, body').animate({
        scrollTop: $("#"+div_id).offset().top - 100
      }, 'smooth');
    }, timeout ? timeout : 0)
  }

  // Header handle click
  const handlerClick = () => {
    setState({ ...state, showMenu: !state.showMenu });
    if (state.showMenu == true) {
        document.body.className = "";
    } else {
        document.body.classList.add("overflow-hidden");
    }
  };
  // Header handle click

  const openHam = () => {
      setOpen(!open);
  };

  const openSearch = (menu_title) => {
      setSearch(!search);
      handlerClick();
      setMenuHeading(menu_title)
  };
  const closeSearch = () => {
      setSearch(!search);
      handlerClick();
  };


  return (
        loading ? (
          <section className={"loader-wrapper"}>
              <div id="loader-wrapper">
                  <div id="loader" class="new-loader">
                      <img className="logo-white" src={BrandLogo} alt="logo"/>
                  </div>
              </div>
          </section>
      ) : 
      <Layout GQLPage="" Layout="Without_Banner" classNames={"search-header person-header details-header"} popular_search_slug="find-team" closeSearch={closeSearch} handlerClick={handlerClick} menu_heading={menu_heading} search={search} open={open}>
        
        <SEO title={myMetaTitle} description={myMetaDesc} image={myMetaImg}/>
        
        <Helmet bodyAttributes={{ class: `body_cls` }} />

        <PersonLinks page_title={people_details && people_details.Name}  />

        {
          people_details && 
          <div className="people_details_wrap">

            <PersonDetails people_details={people_details} handleClick={scrollToPos} />
            
            {people_details.Areas_of_Expertise && <Expertise people_details={people_details} />}

            <div className="gryBdr"></div>
            
            <RelatedNews Title={"Related Insights"} insights={people_details?.news_and_insights} Departments={people_details?.Departments} />

          </div>
        }

        <ContactFormBottom member_name={people_details && people_details?.Name} form_name={people_details && (people_details?.Name).toLowerCase()} to_email_id={people_details && people_details?.Email_ID} to_user_id={people_details && people_details?.Form_Queue_ID} openSearch={openSearch} openHam={openHam} search={search} open={open} enable_mail={true} page_url={typeof window !== 'undefined' ? window.location.href : ''} formLabel={`Individual contact ${people_details && people_details?.Name}`} />

      </Layout>
  )
}

export default PeopleDetailsTemplate