import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll";
import HTMLReactParser from 'html-react-parser';
import "./Expertise.scss"
// Header component

const Expertise = (props) => {
    return (
        <React.Fragment>
            <section className="expertise">
                <Container>
                    <Row>
                        <Col>
                            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                                <div className="expertise-info">
                                    <h2>Areas of Expertise</h2>
                                    <div className="details-list">
                                        {props.people_details.Areas_of_Expertise && HTMLReactParser(props.people_details.Areas_of_Expertise)}
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </section>

        </React.Fragment>
    );
};
export default Expertise;
